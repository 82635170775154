



















































































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { apiGetActivity, apiGetActivityGoods } from '@/api/marketing'
@Component({
    components: {
        LsPagination
    }
})
export default class Detail extends Vue {
    @Inject('visible') visible!: any
    $refs!: { table: any }
    @Prop() value!: any
    @Prop() type!: string
    @Prop({ default: false }) single!: boolean
    name = ''
    goodsName = ''
    activeId = ''
    pager = new RequestPaging()
    showGoods = false
    goodsLists = []
    get selectData() {
        return this.value
    }
    set selectData(val) {
        this.$emit('input', val)
    }
    @Watch('visible', { deep: true, immediate: true })
    visibleChange(val: any) {
        if (val.val) {
            this.getList()
        } else {
            this.showGoods = false
            this.name = ''
            this.goodsLists = []
        }
    }
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiGetActivity,
                params: {
                    keyword: this.name,
                    type: this.type
                }
            })
            .then((res: any) => {})
    }

    getGoodsList() {
        apiGetActivityGoods({
            keyword: this.goodsName,
            type: this.type,
            activity_id: this.activeId
        }).then(res => {
            this.goodsLists = res
        })
    }

    handleCellClick(row: any) {
        this.goodsName = ''
        this.showGoods = true
        this.goodsLists = []
        this.activeId = row.id
        this.getGoodsList()
    }

    handleSelect(val: any[]) {
        this.selectData = val
    }

    selectChange($event: boolean, val: any) {
        if ($event) {
            this.selectData = val
        } else {
            this.selectData = {}
        }
    }
}
