




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Activity from '@/components/activity-select/detail.vue'
import Shop from './shop.vue'
import Category from './category.vue'
import Page from './page.vue'
import Custom from './custom.vue'
import Draw from './draw.vue'

@Component({
  components: {
    Shop,
    Category,
    Page,
    Custom,
    Activity,
    Draw
  }
})
export default class Detail extends Vue {
  @Prop() value!: any
  @Prop({ default: 'mobile' }) client!: string
  activeName: any[] = []
  linkType = 'shop'
  lists =
      this.client == 'mobile'
          ? [
            {
              name: '商城页面',
              key: 'shop',
              children: [
                {
                  name: '基础页面',
                  type: 'shop',
                  link: {
                    path: '',
                    name: '',
                    params: {},
                    type: 'shop'
                  }
                },
                {
                  name: '微页面',
                  type: 'page',
                  link: {
                    path: '/pages/page/page',
                    name: '微页面',
                    params: {},
                    type: 'page'
                  }
                }
              ]
            },
            {
              name: '商品',
              key: 'goods',
              children: [
                {
                  name: '普通商品',
                  type: 'goods',
                  link: {
                    path: '/pages/goods_detail/goods_detail',
                    name: '普通商品',
                    params: {},
                    type: 'goods'
                  }
                },
                //   {
                //       name: '秒杀商品',
                //       type: 'seckill',
                //       link: {
                //           path: '/bundle/pages/seckill_detail/seckill_detail',
                //           name: '秒杀商品',
                //           params: {},
                //           type: 'seckill'
                //       }
                //   },
                //   {
                //       name: '拼团商品',
                //       type: 'team',
                //       link: {
                //           path: '/bundle/pages/goods_team_detail/goods_team_detail',
                //           name: '拼团商品',
                //           params: {},
                //           type: 'team'
                //       }
                //   },
                // {
                //     name: '砍价商品',
                //     type: 'bargain',
                //     link: {
                //         path: '/bundle/pages/bargain_progress/bargain_progress',
                //         name: '砍价商品',
                //         params: {},
                //         type: 'bargain',
                //     },
                // },
                {
                  name: '商品分类',
                  type: 'category',
                  link: {
                    path: '/pages/goods_search/goods_search',
                    name: '商品分类',
                    params: {},
                    type: 'category'
                  }
                }
              ]
            },
            {
              name: '营销活动',
              key: 'marking',
              children: [
                {
                  name: '营销活动页面',
                  type: 'marking',
                  link: {
                    path: '',
                    name: '',
                    params: {},
                    type: 'marking'
                  }
                }
              ]
            },
            {
              name: '特色资源',
              key: 'features',
              children: [
                {
                  name: '特色资源',
                  type: 'features',
                  link: {
                    path: '',
                    name: '',
                    params: {},
                    type: 'features'
                  }
                }
              ]
            },
            {
              name: '其他',
              key: 'other',
              children: [
                {
                  name: '自定义链接',
                  type: 'custom',
                  link: {
                    path: '/pages/webview/webview',
                    name: '自定义链接',
                    params: {
                      url: ''
                    },
                    type: 'custom'
                  }
                }
              ]
            }
          ]
          : [
            {
              name: '商城页面',
              key: 'shop',
              children: [
                {
                  name: '基础页面',
                  type: 'shop',
                  link: {
                    path: '',
                    name: '',
                    params: {},
                    type: 'shop'
                  }
                }
              ]
            },
            {
              name: '商品',
              key: 'goods',
              children: [
                {
                  name: '普通商品',
                  type: 'goods',
                  link: {
                    path: '/goods_details',
                    name: '普通商品',
                    params: {},
                    type: 'goods'
                  }
                },
                {
                  name: '秒杀商品',
                  type: 'seckill',
                  link: {
                    path: '/goods_details_seckill',
                    name: '秒杀商品',
                    params: {},
                    type: 'seckill'
                  }
                },
                {
                  name: '商品分类',
                  type: 'category',
                  link: {
                    path: '/category',
                    name: '商品分类',
                    params: {},
                    type: 'category'
                  }
                }
              ]
            },
            {
              name: '营销活动',
              key: 'marking',
              children: [
                {
                  name: '营销活动页面',
                  type: 'marking',
                  link: {
                    path: '',
                    name: '',
                    params: {},
                    type: 'marking'
                  }
                }
              ]
            }
          ]

  get link (): any {
    let itemLink: any = {}
    this.lists.forEach(item => {
      const citem = item.children.find(citem => citem.type == this.linkType)
      citem && (itemLink = citem)
    })
    return itemLink?.link
  }

  set link (val) {
    this.lists.forEach(item => {
      item.children.forEach(citem => {
        if (citem.type == this.linkType) {
          val && (citem.link = val)
          if (!this.activeName.includes(item.key)) {
            this.activeName.push(item.key)
          }
        }
      })
    })
  }

  @Watch('value', { immediate: true })
  valueChange (val: any) {
    if (!val) {
      return
    }
    this.linkType = val.type || 'shop'
    this.link = val
  }

  @Watch('link', { deep: true })
  linkChange (val: any) {
    this.$emit('input', val)
  }

  setLinkParams (item: any) {
    if (!item.id) {
      return (this.link.params = {})
    }
    this.link &&
    (this.link = {
      ...this.link,
      params: {
        id: item.id,
        name: item.name
      }
    })
  }
}
