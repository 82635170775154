





















import { apipackgeCategoryLists } from "@/api/goods";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class Shop extends Vue {
    $refs!: { cascader: any };
    @Prop() value!: any;

    lists = [];
    get params() {
        return this.value;
    }
    getLists() {
        apipackgeCategoryLists({
            pager_type: 1,
            packge_id: this.$route.query.packge_id,
        }).then((res) => {
            this.lists = res?.lists;
        });
    }
    onSelect() {
        const node = this.$refs.cascader.getCheckedNodes();
        this.$emit("input", node[0].data);
    }
    created() {
        this.getLists();
    }
}
