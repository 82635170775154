










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import { apiThemePageLists } from "@/api/shop";
@Component({
  components: {
    LsPagination,
  },
})
export default class Page extends Vue {
  $refs!: { table: any };
  @Prop() value!: any;
  name = "";
  pager = new RequestPaging();

  get selectData() {
    return this.value;
  }
  set selectData(val) {
    this.$emit("input", val);
  }

  getList(page?: number): void {
    page && (this.pager.page = page);
    this.pager
      .request({
        callback: apiThemePageLists,
        params: {
          type: 1,
          name: this.name,
          ...this.$route.query,
        },
      })
      .then((res: any) => {});
  }
  onSelect($event: any, item: any) {
    if (!$event) {
      this.selectData = {};
      return;
    }
    this.selectData = item;
  }
  created() {
    this.getList();
  }
}
